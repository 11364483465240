.navbar {
    // height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 25px;
    border-bottom: 1px solid #eee;
  
    .search {
      display: flex;
      align-items: center;
      border-radius: 3px;
      border: 1px solid gray;
  
      input {
        border: none;
        outline: none;
        font-size: 14px;
        padding: 6px 10px;
        // color: rgba(255, 255, 255, 0.925);
      }
  
      .search_icon {
        cursor: pointer;
      }
    }
  
    .item_lists {
      display: flex;
      align-items: center;
  
      .item {
        margin-left: 15px;
        display: flex;
        align-items: center;
        position: relative;
  
        .item_icon {
          font-size: 22px;
          cursor: pointer;
  
          &.white {
            color: rgba(255, 255, 255, 0.699);
          }
        }
  
        .badge {
          height: 18px;
          width: 18px;
          border-radius: 50%;
          background-color: #ff74b1;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 13px;
          font-weight: bold;
          position: absolute;
          top: -7px;
          right: -7px;
        }
  
        .admin_pic {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          object-fit: cover;
          cursor: pointer;
        }
      }
    }
  }